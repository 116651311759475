import React, { useEffect, useState } from "react";
import moment from "moment";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useNavigate, useParams } from "react-router-dom";
import DoneIcon from "@mui/icons-material/Done";
import Typography from "@material-ui/core/Typography";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

const GeneralReport = (props) => {
  let params = useParams();
  let navigate = useNavigate();
  const [calendarDays, setCalendarDays] = useState([]);

  const htmlToCSV = (html, filename) => {
    var data = [];
    var rows = document.querySelectorAll("table tr");

    for (var i = 0; i < rows.length; i++) {
      var row = [],
        cols = rows[i].querySelectorAll("td, th");

      for (var j = 0; j < cols.length; j++) {
        row.push(cols[j].innerText);
      }

      data.push(row.join(","));
    }
    downloadCSVFile(data.join("\n"), filename);
  };

  function downloadCSVFile(csv, filename) {
    var csv_file, download_link;

    csv_file = new Blob(["\ufeff" + csv], { type: "text/csv;charset=utf-8" });

    download_link = document.createElement("a");

    download_link.download = filename;

    download_link.href = window.URL.createObjectURL(csv_file);

    download_link.style.display = "none";

    document.body.appendChild(download_link);

    download_link.click();
  }

  const getCalendarDays = (reportDate) => {
    let days = [];
    let daysInMonth = moment(reportDate).daysInMonth();
    for (var i = 1; i <= daysInMonth; i++) {
      var day = i;
      if (i < 10) {
        day = `0${i}`;
      }
      days.push(
        `${moment(reportDate).format("YYYY")}-${moment(reportDate).format(
          "MM"
        )}-${day}`
      );
    }
    return days;
  };

  useEffect(() => {
    setCalendarDays(getCalendarDays(params.reportDate));
  }, []);

  useEffect(() => {
    if (props.reloadItem) {
      props.fetchGeneralReport(params.reportDate);
    }
  }, [props.reloadItem]);

  useEffect(() => {
    window.addEventListener("scroll", function (e) {
      var windowTopPos =
        window.pageYOffset || document.documentElement.scrollTop;
      var reportTblTopPos = document.querySelector("table").offsetTop;

      if (windowTopPos >= reportTblTopPos) {
        this.document.querySelector("table thead").style.transform =
          "translateY(" + (windowTopPos - reportTblTopPos) + "px)";
        this.document.querySelector("table thead").style.backgroundColor =
          "#fff";
      } else {
        this.document.querySelector("table thead").style.transform =
          "translateY(0)";
        this.document.querySelector("table thead").style.backgroundColor =
          "#fff";
      }
    });
  });

  useEffect(() => {
    if (document.querySelector(".chamcongApp__generalReport_tablewrp")) {
      document
        .querySelector(".chamcongApp__generalReport_tablewrp")
        .addEventListener("scroll", function (e) {
          var elScrollLeftPos = document.querySelector(
            ".chamcongApp__generalReport_tablewrp"
          ).scrollLeft;

          var thisElRow = document.getElementsByClassName("nhanvien-row");

          if (elScrollLeftPos > 0) {
            for (var i = 0; i < thisElRow.length; i++) {
              thisElRow[i].style.transform =
                "translateX(" + elScrollLeftPos + "px)";
              thisElRow[i].style.backgroundColor = "#f7f4f4";
            }
          } else {
            for (var i = 0; i < thisElRow.length; i++) {
              thisElRow[i].style.transform = "translateX(0)";
              thisElRow[i].style.backgroundColor = "#f7f4f4";
            }
          }

          console.log(elScrollLeftPos);
        });
    }
  });

  return (
    <>
      {props.generalReport.length ? (
        <>
          <Typography m={2} variant="h4" gutterBottom>
            Báo Cáo Tổng Hợp - {moment(params.reportDate).format("MM/YYYY")}
          </Typography>
          <ButtonGroup
            disableElevation
            variant="contained"
            style={{
              margin: "15px 0",
              padding: "0px 10px",
              display: "flex",
            }}
          >
            <Button
              style={{ color: "#fff", border: "1px solid #fff" }}
              onClick={(e) => {
                navigate(`/`);
              }}
            >
              <ArrowBackIosIcon /> Quay lại
            </Button>
            <Button
              style={{ color: "#fff", border: "1px solid #fff" }}
              onClick={(e) => {
                var html = document.querySelector("table").outerHTML;
                htmlToCSV(
                  html,
                  "Bao cao tong hop thang " +
                    moment(params.reportDate).format("MM-YYYY") +
                    ".csv"
                );
              }}
            >
              <FileDownloadIcon /> Xuất CSV File
            </Button>
          </ButtonGroup>
          <TableContainer
            component={Paper}
            className="chamcongApp__generalReport_tablewrp"
          >
            <Table
              className="chamcongApp__generalReport_table"
              sx={{ minWidth: 650 }}
              aria-label="simple table"
            >
              <TableHead>
                <TableRow>
                  <TableCell key="nhanvien58jgh7">N.Viên</TableCell>
                  <TableCell key="nhanvien58jgh8">C.Vụ</TableCell>
                  {calendarDays.map((dateRow, dateRowKey) => (
                    <TableCell key={dateRowKey} align="right">
                      {moment(dateRow).format("DD/MM")}
                    </TableCell>
                  ))}
                  <TableCell key="nhanvien59847h">T.Ca</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {props.generalReport.map((reportRow) => (
                  <TableRow key={reportRow.id}>
                    <TableCell className="nhanvien-row">
                      {reportRow.full_name}
                    </TableCell>
                    <TableCell className="nhanvien-row">
                      {reportRow.staff_role}
                    </TableCell>
                    {calendarDays.map((dateRow, dateRowKey) => (
                      <TableCell key={dateRowKey} align="right">
                        {reportRow.shifts.find(
                          (shiftRow) =>
                            moment(shiftRow.mark_date).format("YYYY-MM-DD") ===
                              dateRow &&
                            (shiftRow.morning_checked === 1 ||
                              shiftRow.afternoon_checked === 1 ||
                              shiftRow.evening_checked === 1)
                        ) ? (
                          <>
                            <span>
                              {props.calTotalShift(
                                reportRow.shifts.filter(
                                  (item) =>
                                    moment(item.mark_date).format(
                                      "YYYY-MM-DD"
                                    ) === dateRow
                                )
                              )}
                            </span>
                          </>
                        ) : (
                          ""
                        )}
                      </TableCell>
                    ))}
                    <TableCell>
                      {props.calTotalShift(reportRow.shifts)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      ) : (
        "Loading...."
      )}
    </>
  );
};

export default GeneralReport;
