import "./App.css";
import { useState, useEffect } from "react";
//lib
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import moment from "moment";

import Report from "./components/Report";
import ChamCong from "./components/ChamCong";
import GeneralReport from "./components/GeneralReport";
import RefreshIcon from "@mui/icons-material/Refresh";

function App() {
  const apiURL = "https://phoenixchamcong-server.phoenixpalacehd.com/api/";
  //const apiURL = "http://phoenixchamcong-server.lilcasoft.ca/api/";

  const staffRoles = [
    "Quản lý",
    "Kế toán",
    "Phục vụ",
    "Karaoke",
    "Kinh doanh",
    "Bảo vệ",
    "Tạp vụ",
    "Pha chế",
    "Bếp trưởng",
    "Phụ bếp",
    "Bếp phó",
    "Đầu bếp",
    "Marketing",
  ];

  const [staffs, setStaffs] = useState([]);
  const [generalReport, setGeneralReport] = useState([]);
  const [staff, setStaff] = useState([]);
  const [reloadItem, setReloadItem] = useState(true);
  const [dataLoadingStatus, setDataLoadingStatus] = useState(true); //track loading table status
  const [filterDate, setFilterDate] = useState("");
  const [reportDate, setReportDate] = useState(moment().format("YYYY-MM-DD"));

  const fetchStaffs = async (requestDate = "") => {
    const results = await axios.get(apiURL + "staffs?date=" + requestDate);
    setStaffs(results.data);
    setReloadItem(false);
    document.querySelector(".loading-screen").style.display = "none";
    if (requestDate !== "") {
      toast.dismiss();
      toast.success(
        "Hiển thị dữ liệu ngày " +
          moment(requestDate, "YYYY-MM-DD").format("DD/MM/YYYY"),
        {
          position: "bottom-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    }
    setTimeout(() => {
      setDataLoadingStatus(false);
    }, 1000);
  };

  const calTotalShift = (shifts) => {
    if (shifts.length) {
      let mapShifts = shifts.map((item) => {
        let totalShift =
          (item.morning_checked === 1 ? 0.5 : 0) +
          (item.afternoon_checked === 1 ? 0.5 : 0);
        return totalShift;
      });
      return mapShifts.reduce((a, b) => a + b);
    }
  };

  const fetchGeneralReport = async (reportDate) => {
    const results = await axios.get(
      apiURL + "general_report/date/" + reportDate
    );
    setGeneralReport(results.data);
    setReloadItem(false);
  };

  const fetchStaff = async (staffId) => {
    const result = await axios.get(apiURL + "staffs/id/" + staffId);
    setStaff(result.data);
    setReloadItem(false);
    setTimeout(() => {
      setDataLoadingStatus(false);
    }, 1000);
  };

  const addStaff = async (newStaff) => {
    await axios
      .post(apiURL + "staffs", newStaff)
      .then((response) => {
        if (response) {
          toast.dismiss();
          toast.success(response.data.msg, {
            position: "bottom-center",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setReloadItem(true);
          document.querySelector(".loading-screen").style.display = "none";
        }
      })
      .catch((error) => {
        console.log("Error ========>", error);
        document.querySelector(".loading-screen").style.display = "none";
      });
  };

  const updateStaff = async (staff) => {
    await axios
      .put(apiURL + "staffs/id/" + staff.id, staff)
      .then((response) => {
        if (response) {
          toast.dismiss();
          toast.success(response.data.msg, {
            position: "bottom-center",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setReloadItem(true);
          document.querySelector(".loading-screen").style.display = "none";
        }
      })
      .catch((error) => {
        console.log("Error ========>", error);
        document.querySelector(".loading-screen").style.display = "none";
      });
  };

  const deleteStaff = async (staff) => {
    await axios
      .delete(apiURL + "staffs/id/" + staff.id, staff)
      .then((response) => {
        if (response) {
          toast.dismiss();
          toast.success(response.data.msg, {
            position: "bottom-center",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setReloadItem(true);
          document.querySelector(".loading-screen").style.display = "none";
        }
      })
      .catch((error) => {
        console.log("Error ========>", error);
        document.querySelector(".loading-screen").style.display = "none";
      });
  };

  const deleteShift = async (shift) => {
    await axios
      .delete(apiURL + "shifts/id/" + shift.id, shift)
      .then((response) => {
        if (response) {
          toast.dismiss();
          toast.success(response.data.msg, {
            position: "bottom-center",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setReloadItem(true);
          document.querySelector(".loading-screen").style.display = "none";
        }
      })
      .catch((error) => {
        console.log("Error ========>", error);
        document.querySelector(".loading-screen").style.display = "none";
      });
  };

  useEffect(() => {
    if (reloadItem) {
      document.querySelector(".loading-screen").style.display = "flex";
      fetchStaffs(filterDate);
    }
  }, [reloadItem]);

  useEffect(() => {
    document.querySelector(".loading-screen").style.display = "flex";
    fetchStaffs(filterDate);
  }, [filterDate]);

  return (
    <>
      <div className="chamcongApp">
        <BrowserRouter>
          <Routes>
            <Route
              exact
              path="/"
              element={
                <ChamCong
                  staffs={staffs}
                  staffRoles={staffRoles}
                  setStaffs={setStaffs}
                  addStaff={addStaff}
                  deleteStaff={deleteStaff}
                  updateStaff={updateStaff}
                  dataLoadingStatus={dataLoadingStatus}
                  setReloadItem={setReloadItem}
                  reportDate={reportDate}
                  setReportDate={setReportDate}
                  filterDate={filterDate}
                  setFilterDate={setFilterDate}
                  fetchGeneralReport={fetchGeneralReport}
                />
              }
            />
            <Route
              path={"general_report/:reportDate"}
              element={
                <GeneralReport
                  reloadItem={reloadItem}
                  setReloadItem={setReloadItem}
                  reportDate={reportDate}
                  setReportDate={setReportDate}
                  generalReport={generalReport}
                  fetchGeneralReport={fetchGeneralReport}
                  calTotalShift={calTotalShift}
                />
              }
            ></Route>
            <Route
              path={"report/:staffId"}
              element={
                <Report
                  staff={staff}
                  fetchStaff={fetchStaff}
                  deleteShift={deleteShift}
                  setStaff={setStaff}
                  reloadItem={reloadItem}
                  setReloadItem={setReloadItem}
                  dataLoadingStatus={dataLoadingStatus}
                  calTotalShift={calTotalShift}
                />
              }
            ></Route>
          </Routes>
        </BrowserRouter>
      </div>
      <div className="loading-screen">
        <RefreshIcon className="loading-screen-icon" />
        <span style={{ margin: "0 5px" }}>
          Đang xử lý yêu cầu...Vui lòng chờ!!
        </span>
      </div>
      <ToastContainer
        position="bottom-center"
        autoClose={500}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
}

export default App;
