import React, { useEffect, useState, useRef } from "react";
import { Routes, Route, useParams, useNavigate } from "react-router-dom";
import MaterialTable, { MTableToolbar } from "material-table";
import moment from "moment";
import TextField from "@material-ui/core/TextField";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import DoneIcon from "@mui/icons-material/Done";

const Report = (props) => {
  let params = useParams();
  let navigate = useNavigate();
  const tableRef = React.createRef();

  const [selectedFilterShifts, setSelectedFilterShifts] = useState([]);
  const shiftSumaryRef = useRef();

  useEffect(() => {
    if (props.reloadItem) {
      props.fetchStaff(params.staffId);
      window.scrollTo(0, 0);
    }
  }, [props.reloadItem]);

  useEffect(() => {
    if (selectedFilterShifts.length > 0) {
      var totalShiftCount = 0;
      var totalMorningShiftCount = 0;
      var totalAfternoonShiftCount = 0;
      var totalEveningShiftCount = 0;

      selectedFilterShifts.forEach((item) => {
        totalMorningShiftCount += item.morning_checked === 1 ? 0.5 : 0;
        totalAfternoonShiftCount += item.afternoon_checked === 1 ? 0.5 : 0;
        //totalEveningShiftCount += item.evening_checked === 1 ? 1 : 0;
      });
      totalShiftCount += totalMorningShiftCount + totalAfternoonShiftCount;

      document.querySelector(".totalShiftCountRef").innerHTML = totalShiftCount;
      document.querySelector(".totalMorningShiftCountRef").innerHTML =
        totalMorningShiftCount;
      document.querySelector(".totalAfternoonShiftCountRef").innerHTML =
        totalAfternoonShiftCount;
      //document.querySelector(".totalEveningShiftCountRef").innerHTML = totalEveningShiftCount;

      shiftSumaryRef.current.style.display = "flex";
    } else {
      shiftSumaryRef.current.style.display = "none";
    }
  }, [selectedFilterShifts]);

  return (
    <>
      <MaterialTable
        title={`Báo Cáo Chấm Công - ${props.staff.full_name} (${props.staff.staff_role})`}
        tableRef={tableRef}
        components={{
          Toolbar: (props) => (
            <div>
              <MTableToolbar {...props} />
              <div>
                <ButtonGroup
                  disableElevation
                  variant="contained"
                  style={{
                    margin: "15px 0",
                    padding: "0px 10px",
                    display: "flex",
                  }}
                >
                  <Button
                    color="primary"
                    onClick={(e) => {
                      navigate(`/`);
                    }}
                  >
                    <ArrowBackIosIcon /> Quay lại
                  </Button>
                </ButtonGroup>
                <TableContainer
                  ref={shiftSumaryRef}
                  component={Paper}
                  style={{ display: "none" }}
                >
                  <Table
                    sx={{ minWidth: 650 }}
                    aria-label="simple table"
                    style={{ border: "1px solid #ddd" }}
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">
                          <strong>Tổng Số Ca</strong>
                        </TableCell>
                        <TableCell align="center">
                          <strong>Tổng ca sáng</strong>
                        </TableCell>
                        <TableCell align="center">
                          <strong>Tổng ca chiều</strong>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow
                        key="1"
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell align="center">
                          <span className="totalShiftCountRef"></span>
                        </TableCell>
                        <TableCell align="center">
                          <span className="totalMorningShiftCountRef"></span>
                        </TableCell>
                        <TableCell align="center">
                          <span className="totalAfternoonShiftCountRef"></span>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </div>
          ),
        }}
        columns={[
          {
            title: "Ngày Giờ",
            field: "mark_date",
            type: "date",
            editComponent: (props) => {
              return (
                <TextField
                  id={`mark_date_${props.id}`}
                  label="Ngày Giờ"
                  type="date"
                  defaultValue={moment(props.value).format("YYYY-MM-DD")}
                  onChange={(e) => props.onChange(e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              );
            },
            render: (rowData) =>
              moment(rowData.mark_date).format("DD/MM/YYYY h:mm A"),
          },
          {
            title: "Sáng",
            field: "morning_checked",
            type: "number",
            filtering: false,
            editComponent: (props) => {
              return (
                <input
                  type="checkbox"
                  checked={props.value === 0 ? false : true}
                  onChange={(e) => props.onChange(e.target.checked)}
                />
              );
            },
            render: (rowdata) => {
              return rowdata.morning_checked === 1 ? (
                <DoneIcon color="success" />
              ) : (
                ""
              );
            },
          },
          {
            title: "Chiều",
            field: "afternoon_checked",
            type: "number",
            filtering: false,
            editComponent: (props) => {
              return (
                <input
                  type="checkbox"
                  checked={props.value === 0 ? false : true}
                  onChange={(e) => props.onChange(e.target.checked)}
                />
              );
            },
            render: (rowdata) => {
              return rowdata.afternoon_checked === 1 ? (
                <DoneIcon color="success" />
              ) : (
                ""
              );
            },
          },
          { title: "Ghi chú", field: "note", filtering: false },
        ]}
        isLoading={props.dataLoadingStatus}
        data={props.staff.shifts}
        options={{
          selection: true,
          filtering: true,
          exportButton: { csv: true },
          paging: true,
          pageSize: 100,
          addRowPosition: "first",
          actionsColumnIndex: -1,
        }}
        editable={{
          //   onRowAdd: (newData) =>
          //     new Promise((resolve, reject) => {
          //       setTimeout(() => {
          //         props.setShifts([...props.shifts, newData]);
          //         //append extra values
          //         newData.project_id = props.selectedProject.id;
          //         newData.is_paid = newData.is_paid ? 1 : 0;
          //         newData.working_date = !newData.working_date
          //           ? moment().format("YYYY-MM-DD")
          //           : newData.working_date;
          //         newData.hourly_rate = !newData.hourly_rate
          //           ? props.selectedProject.default_rate
          //           : newData.hourly_rate;
          //         props.addShift(newData);
          //         tableRef.current && tableRef.current.onQueryChange();
          //         resolve();
          //       }, 1000);
          //     }),
          onRowDelete: (oldData) =>
            new Promise((resolve, reject) => {
              setTimeout(() => {
                const dataDelete = [...props.staff.shifts];
                const index = oldData.tableData.id;

                //send delete request here
                props.deleteShift(dataDelete[index]);

                dataDelete.splice(index, 1);
                props.setStaff([...dataDelete]);

                resolve();
              }, 1000);
            }),
          //   onBulkUpdate: (changes) =>
          //     new Promise((resolve, reject) => {
          //       setTimeout(() => {
          //         const rows = Object.values(changes);
          //         const dataUpdate = [...props.shifts];

          //         rows.map((item) => {
          //           const index = item.oldData.tableData.id;
          //           dataUpdate[index] = item.newData;
          //           props.setShifts([...dataUpdate]);

          //           //send update here
          //           props.updateShift(dataUpdate[index]);
          //         });

          //         resolve();
          //       }, 1000);
          //     }),
        }}
        onSelectionChange={(rows) => {
          setSelectedFilterShifts(rows);
        }}
      />
    </>
  );
};

export default Report;
