import React, { useState } from "react";
import Typography from "@material-ui/core/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import AssessmentIcon from "@mui/icons-material/Assessment";
import AddIcon from "@mui/icons-material/Add";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import CachedIcon from "@mui/icons-material/Cached";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import TextField from "@mui/material/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import packageJson from "../../package.json";

const ChamCong = (props) => {
  const vnDays = [
    "Chủ nhật",
    "Thứ hai",
    "Thứ ba",
    "Thứ tư",
    "Thứ năm",
    "Thứ sáu",
    "Thứ bảy",
  ];

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    color: "#000",
    display: "flex",
    flexFlow: "column",
    alignItems: "center",
  };

  var moment = require("moment-timezone");

  const [openAddModal, setOpenAddModal] = useState(false);
  const handleOpenAddModal = () => setOpenAddModal(true);
  const handleCloseAddModal = () => setOpenAddModal(false);

  const [openFilterDateModal, setOpenFilterDateModal] = useState(false);
  const handleOpenFilterDateModal = () => setOpenFilterDateModal(true);
  const handleCloseFilterDateModal = () => setOpenFilterDateModal(false);

  const [openAddNoteModal, setOpenAddNoteModal] = useState(false);
  const handleOpenAddNoteModal = () => setOpenAddNoteModal(true);
  const handleCloseAddNoteModal = () => setOpenAddNoteModal(false);

  const [newStaff, setNewStaff] = useState("");

  const [selectEditRow, setSelectEditRow] = useState({});
  const [editNote, setEditNote] = useState("");

  let navigate = useNavigate();
  let params = useParams();

  const addNewStaff = () => {
    if (newStaff === "") {
      alert("Vui lòng nhập tên nhân viên mới!");
    } else {
      document.querySelector(".loading-screen").style.display = "flex";
      const formData = {
        full_name: newStaff,
      };
      props.addStaff(formData);
      setNewStaff("");
      setOpenAddModal(false);
    }
  };

  const setMorningStatus = (e, itemChange) => {
    document.querySelector(".loading-screen").style.display = "flex";
    itemChange.today_morning_checked = e.target.checked;
    itemChange.request_date = props.filterDate;
    const index = props.staffs.findIndex((item) => {
      return item.id === itemChange.id;
    });

    const dataUpdate = [...props.staffs];
    dataUpdate[index] = itemChange;
    props.setStaffs([...dataUpdate]);
    //send update here
    props.updateStaff(dataUpdate[index]);
  };

  const setAfternoonStatus = (e, itemChange) => {
    document.querySelector(".loading-screen").style.display = "flex";
    itemChange.today_afternoon_checked = e.target.checked;
    itemChange.request_date = props.filterDate;
    const index = props.staffs.findIndex((item) => {
      return item.id === itemChange.id;
    });

    const dataUpdate = [...props.staffs];
    dataUpdate[index] = itemChange;
    props.setStaffs([...dataUpdate]);
    //send update here
    props.updateStaff(dataUpdate[index]);
  };

  const setEveningStatus = (e, itemChange) => {
    document.querySelector(".loading-screen").style.display = "flex";
    itemChange.today_evening_checked = e.target.checked;
    itemChange.request_date = props.filterDate;
    const index = props.staffs.findIndex((item) => {
      return item.id === itemChange.id;
    });

    const dataUpdate = [...props.staffs];
    dataUpdate[index] = itemChange;
    props.setStaffs([...dataUpdate]);
    //send update here
    props.updateStaff(dataUpdate[index]);
  };

  const saveNote = (newNote, itemChange) => {
    if (newNote !== itemChange.today_note) {
      document.querySelector(".loading-screen").style.display = "flex";
      itemChange.today_note = newNote;
      itemChange.request_date = props.filterDate;

      const index = props.staffs.findIndex((item) => {
        return item.id === itemChange.id;
      });

      const dataUpdate = [...props.staffs];
      dataUpdate[index] = itemChange;
      props.setStaffs([...dataUpdate]);
      //send update here
      props.updateStaff(dataUpdate[index]);
      setEditNote("");
    }
  };

  const saveName = (e, itemChange) => {
    if (e.target.value !== itemChange.full_name) {
      document.querySelector(".loading-screen").style.display = "flex";
      //diff check
      itemChange.full_name = e.target.value;
      itemChange.request_date = props.filterDate;
      const index = props.staffs.findIndex((item) => {
        return item.id === itemChange.id;
      });

      const dataUpdate = [...props.staffs];
      dataUpdate[index] = itemChange;
      props.setStaffs([...dataUpdate]);
      //send update here
      props.updateStaff(dataUpdate[index]);
    }
  };

  const saveStaffRole = (e, itemChange) => {
    if (e.target.value !== itemChange.staff_role) {
      document.querySelector(".loading-screen").style.display = "flex";
      //diff check
      itemChange.staff_role = e.target.value;
      itemChange.request_date = props.filterDate;
      const index = props.staffs.findIndex((item) => {
        return item.id === itemChange.id;
      });

      const dataUpdate = [...props.staffs];
      dataUpdate[index] = itemChange;
      props.setStaffs([...dataUpdate]);
      //send update here
      props.updateStaff(dataUpdate[index]);
    }
  };

  const deleteStaff = (itemChange) => {
    var confirmBox = window.confirm("Bạn có chắc muốn xoá nhân viên này?");
    if (confirmBox) {
      props.deleteStaff(itemChange);
    } else {
      //ntd
    }
  };

  return (
    <>
      <Typography m={2} variant="h4" gutterBottom>
        Chấm Công Phoenix
      </Typography>
      <div
        style={{
          display: "flex",
          flexFlow: "column",
          maxWidth: "300px",
          margin: "0 auto",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <label>Chọn ngày chấm công</label>
        <input
          className="clean-input"
          type="date"
          placeholder="Start Date"
          defaultValue={props.filterDate}
          onChange={(e) => {
            props.setFilterDate(e.target.value);
          }}
        />
      </div>
      <Typography variant="h6" component="h6">
        {`Hôm nay: ${
          vnDays[moment().tz("Asia/Ho_Chi_Minh").format("e")]
        }, ${moment().tz("Asia/Ho_Chi_Minh").format("DD/MM/YYYY")}`}
      </Typography>
      <ButtonGroup
        disableElevation
        variant="outline"
        style={{ margin: "15px 0" }}
      >
        <Button onClick={handleOpenAddModal}>
          <AddIcon />
        </Button>

        <Button onClick={handleOpenFilterDateModal}>
          <AnalyticsIcon />
        </Button>
        <Button
          onClick={(e) => {
            window.location.reload();
          }}
        >
          <CachedIcon />
        </Button>
      </ButtonGroup>
      <TableContainer component={Paper}>
        <Table
          className="chamcongApp__table"
          sx={{ minWidth: 650 }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              <TableCell>
                <strong>N.Viên</strong>
              </TableCell>
              <TableCell align="right">
                <strong>Sáng</strong>
              </TableCell>
              <TableCell align="right">
                <strong>Chiều</strong>
              </TableCell>
              <TableCell align="left">
                <strong>Ghi chú</strong>
              </TableCell>
              <TableCell align="center"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.staffs.map((row) => (
              <TableRow
                key={row.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell scope="row">
                  <input
                    className="clean-input"
                    type="text"
                    defaultValue={row.full_name}
                    onBlur={(e) => saveName(e, row)}
                  />
                  <FormControl fullWidth>
                    <InputLabel>Chức vụ</InputLabel>
                    <Select
                      style={{ fontSize: "13px" }}
                      value={row.staff_role}
                      onChange={(e) => saveStaffRole(e, row)}
                    >
                      {props.staffRoles.sort().map((role, roleKey) => (
                        <MenuItem key={roleKey} value={role}>
                          {role}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </TableCell>
                <TableCell align="right">
                  <input
                    type="checkbox"
                    checked={row.today_morning_checked === 0 ? false : true}
                    onChange={(e) => setMorningStatus(e, row)}
                  />
                </TableCell>
                <TableCell align="right">
                  <input
                    type="checkbox"
                    checked={row.today_afternoon_checked === 0 ? false : true}
                    onChange={(e) => setAfternoonStatus(e, row)}
                  />
                </TableCell>
                <TableCell align="right">
                  <div
                    className="clean-input"
                    onClick={(e) => {
                      setSelectEditRow(row);
                      handleOpenAddNoteModal();
                    }}
                  >
                    {row.today_note}
                  </div>
                </TableCell>
                <TableCell align="right">
                  <Button
                    onClick={(e) => {
                      props.setReloadItem(true);
                      navigate(`/report/${row.id}`);
                    }}
                  >
                    <AssessmentIcon />
                  </Button>
                  <Button color="error" onClick={(e) => deleteStaff(row)}>
                    <DeleteIcon />
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <p>
        {`Version ${packageJson.version} - `}
        <a style={{ color: "#fff" }} href="https://lilcasoft.ca">
          lilcasoft.ca
        </a>
      </p>
      <Modal
        open={openAddModal}
        onClose={handleCloseAddModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Thêm Nhân Viên
          </Typography>
          <input
            className="clean-input"
            type="text"
            defaultValue={newStaff}
            placeholder="Họ và tên nhân viên mới..."
            onChange={(e) => setNewStaff(e.target.value)}
          />
          <ButtonGroup variant="contained" style={{ margin: "15px 0" }}>
            <Button color="success" onClick={addNewStaff}>
              Tạo mới
            </Button>
            <Button color="error" onClick={(e) => setOpenAddModal(false)}>
              Thoát
            </Button>
          </ButtonGroup>
        </Box>
      </Modal>

      <Modal
        open={openAddNoteModal}
        onClose={handleCloseAddNoteModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Ghi chú
          </Typography>
          <input
            className="clean-input"
            type="text"
            value={editNote}
            placeholder="nhập ghi chú..."
            onChange={(e) => setEditNote(e.target.value)}
          />
          <ButtonGroup variant="contained" style={{ margin: "15px 0" }}>
            <Button
              color="success"
              onClick={(e) => {
                saveNote(editNote, selectEditRow);
                handleCloseAddNoteModal();
              }}
            >
              Lưu
            </Button>
            <Button
              color="error"
              onClick={(e) => {
                setEditNote("");
                setOpenAddNoteModal(false);
              }}
            >
              Thoát
            </Button>
          </ButtonGroup>
        </Box>
      </Modal>

      <Modal
        open={openFilterDateModal}
        onClose={handleCloseFilterDateModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            style={{ marginBottom: "15px" }}
          >
            Báo Cáo Tháng
          </Typography>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              views={["year", "month"]}
              label="Year and Month"
              value={props.reportDate}
              onChange={(newValue) => {
                props.setReportDate(moment(newValue).format("YYYY-MM-DD"));
              }}
              renderInput={(params) => (
                <TextField {...params} helperText={null} />
              )}
            />
          </LocalizationProvider>
          <ButtonGroup variant="contained" style={{ margin: "15px 0" }}>
            <Button
              color="success"
              onClick={(e) => {
                if (props.reportDate === "") {
                  alert("Vui lòng chọn năm và tháng để xem báo cáo tổng hợp!");
                } else {
                  props.setReloadItem(true);
                  navigate(`/general_report/${props.reportDate}`);
                }
              }}
            >
              Xem
            </Button>
            <Button
              color="error"
              onClick={(e) => setOpenFilterDateModal(false)}
            >
              Thoát
            </Button>
          </ButtonGroup>
        </Box>
      </Modal>
    </>
  );
};

export default ChamCong;
